import { Link as Anchor, Box, Flex, Heading, Text } from "@chakra-ui/react";
import AddBasketButton, { sellableWithoutStockFunc } from "@components/AddBasketButton";
import { ProductTeaserType } from "@components/ProductList/ProductList.model";
import getPriceFormat from "@hooks/getPriceFormat";
import { useAppData } from "@hooks/useAppData";
import { useTranslations } from "next-intl";
import NextLink from "next/link";

function ProductTeaserInfoComponent(
  data: ProductTeaserType & {
    variants?: number;
    hideBuyButton?: boolean;
    centerText?: boolean;
    designer?: string;
    isSearchPage?: boolean;
    showVariants?: boolean;
  }
) {
  const {
    PageUrl,
    Title,
    FormattedConfiguration,
    Configuration,
    Price,
    ListedPrice,
    FormattedPrice,
    FormattedListedPrice,
    Currency,
    Sku,
    variants,
    centerText = false,
    designer,
    hideBuyButton,
    isSearchPage = false,
    showVariants,
  } = data;
  const dict = useTranslations("ProductList");
  const galleryDict = useTranslations("ProductPage.Gallery");
  const productPageHeroDict = useTranslations("ProductPage.Hero");

  const [
    {
      marketData: { PurchasesEnabled, Currency: MarketCurrency },
    },
  ] = useAppData();

  // If a product has a NotEnabledText other than "Not in stock",
  // such as "coming soon", we hide the price.
  const showButton = sellableWithoutStockFunc(
    data.Stock,
    data.SellWithoutStock
  );
  const specialButtonText =
    data?.NotEnabledText !==
    productPageHeroDict("StockNotAvailable");
  const showPrice = showButton && !specialButtonText;

  return (
    <Box mt={2} mb={4} textAlign={centerText ? "center" : "unset"}>
      {!isSearchPage && hideBuyButton && !showVariants && (
        <Text fontSize="sm">{designer}</Text>
      )}
      <Heading
        as="h1"
        size="sm"
        marginY={!isSearchPage && hideBuyButton && !showVariants ? 2 : ""}
        height={!isSearchPage && hideBuyButton && !showVariants ? "2.5rem" : ""}
        data-sku={Sku}
      >
        <NextLink href={PageUrl} passHref>
          <Anchor>{Title}</Anchor>
        </NextLink>
      </Heading>
      {(isSearchPage || !hideBuyButton || showVariants) && (
        <Text fontSize="sm" marginY={2} noOfLines={2} height="2.5rem">
          {FormattedConfiguration || Configuration}
        </Text>
      )}

      {showPrice && PurchasesEnabled ? (
        <Flex justifyContent={centerText ? "center" : "flex-start"}>
          {Price < ListedPrice && (
            <Text
              fontSize="lg"
              fontWeight="bold"
              textDecoration="line-through"
              marginY={1}
              pr={2}
            >
              {getPriceFormat(FormattedListedPrice, Currency || MarketCurrency)}
            </Text>
          )}
          <Text fontSize="lg" fontWeight="bold" marginY={1}>
            {getPriceFormat(FormattedPrice, Currency || MarketCurrency)}
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      <Box
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        flexWrap="wrap"
        justifyContent={centerText ? "center" : "flex-start"}
        alignItems={{ base: centerText ? "center" : "baseline", sm: "baseline" }}
      >
        <AddBasketButton
          Sku={Sku}
          Stock={data.Stock}
          SellWithoutStock={data.SellWithoutStock}
          PurchaseNotEnabled={data.PurchaseNotEnabled}
          NotEnabledText={data.NotEnabledText}
          ProductionDays={data.ProductionDays}
          IsPreOrder={data.IsPreOrder}
          hideBuyButton={data.hideBuyButton}
          price={data.Price}
          size="sm"
          mb={{ base: 4, sm: 0 }}
          mr={{ base: centerText ? 0 : 8, sm: 8 }}
        />
        <NextLink href={PageUrl} passHref>
          <Anchor fontSize="sm" mx="unset">
            {variants > 1
              ? galleryDict("AllVariantsLabelCarousel", { 0: variants })
              : dict("LinkViewProduct")}
          </Anchor>
        </NextLink>
      </Box>
    </Box>
  );
}

export default ProductTeaserInfoComponent;
